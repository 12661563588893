/* You can add global styles to this file, and also import other style files */
$arabic_unicode_range: U+0600-06FF;

@font-face {
  font-family: janna;
  src: url("./assets/fonts/Janna/JannaLT-Regular.ttf");
  font-weight: normal;
  unicode-range: $arabic_unicode_range;
}

@font-face {
  font-family: janna;
  src: url("./assets/fonts/Janna/JannaLT-Bold.ttf");
  font-weight: bold;
  unicode-range: $arabic_unicode_range;
}

@font-face {
  font-family: baloo-chettan;
  src: url("./assets/fonts/Baloo_Chettan_2/BalooChettan2-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: baloo-chettan;
  src: url("./assets/fonts/Baloo_Chettan_2/BalooChettan2-Bold.ttf");
  font-weight: bold;
}


@font-face {
  font-family: baloo-chettan;
  src: url("./assets/fonts/Baloo_Chettan_2/BalooChettan2-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: baloo-chettan;
  src: url("./assets/fonts/Baloo_Chettan_2/BalooChettan2-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: baloo-chettan;
  src: url("./assets/fonts/Baloo_Chettan_2/BalooChettan2-Medium.ttf");
  font-weight: 500;
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css";
//@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  font-family: baloo-chettan, serif;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';


//.mat-drawer-container {
//  background-color: #908f8f;
//}

@import "sass/custom";
