@import 'variables';

.card-in-listing {
  margin: 0 20px 20px 0;
  width: 275px !important;
  float: left;
}

.cards-list {
  margin: 10px 0;
}

.tile-in-listing {
  margin: 0 20px 20px 0;
  //width: 275px !important;
}

.tiles-list {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.main-actions {
  margin-right: 10px;
  padding-right: 10px;
}

.input-group-prepend, .input-group-append {
  display: block;
}

.sb-success {
  background-color: $green;
  color: white;
}

.sb-error {
  background-color: red;
  color: white;
  max-width: 90% !important;
  min-width: 50% !important;
}

.sb-failure {
  @extend .sb-error
}

.sb-partial-success {
  @extend .sb-error;
  background-color: orange;
}

.full-wid {
  width: 100%;
}


.mat-button-toggle-label-content {
  padding: 0 7px !important;
  line-height: 26px !important;;
}
